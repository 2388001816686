import DNA from '/src/assets/js/dna.js'
import Individual from '/src/assets/js/individual.js'

function constrain (n, low, high) {
	return Math.max(Math.min(n, high), low)
}

function mapVal (val, start1, stop1, start2, stop2) {
	let newval = (val - start1) / (stop1 - start1) * (stop2 - start2) + start2
	if (val < start1 || val > stop1) {
		return newval
	}
	if (start2 < stop2) {
		return constrain(newval, start2, stop2);
	} else {
		return constrain(newval, stop2, start2);
	}
}

class Population {
	constructor(m, num) {
		this.mutationRate = m; // Mutation rate
		this.population = []; // array to hold the current population
		this.matingPool = []
		this.generations = 0; // Number of generations
		for (let i = 0; i < num; i++) {
			this.population[i] = new Individual(i, new DNA(), this.generations)
		}
	}

	// Display all faces
	display(scenes) {
		//let slots = this.generateSlots()

		for (let i = 0; i < this.population.length; i++) {
			this.population[i].display(scenes[i])
		}

		let genNum = document.getElementById('gen-num')
		genNum.innerHTML = `Generation ${this.generations}`
	}

	generateSlots() {
		let boxsize = 120, 
			slots = [],
			currentPos = 0

		if (this.population.length % 2 == 0) {
			//slots[0] = 0
			slots[0] = - (boxsize/2) - (this.population.length / 2) * (boxsize/2)
		} else {
			//slots[0] = boxsize/2
			slots[0] =  - (this.population.length / 2) * (boxsize) + (boxsize/2) 
		}

		currentPos = slots[0]

		for (let i = 1; i < this.population.length; i ++) {
			currentPos = currentPos + boxsize
			slots.push(currentPos)
		}
		return slots
	}

	// Are we rolling over any of the faces?
	rollover() {
		for (let i = 0; i < this.population.length; i++) {
			this.population[i].rollover()
		}
	}

	// Generate a mating pool
	selection(choice) {
		let maxFitness = this.population.length * 2,
			currentFit = 1

		this.matingPool = []
		
		for (let i = 0; i < this.population.length; i++) {
			
			if (i == choice) {
				currentFit = maxFitness
			} else {
				currentFit = 1
			}

			let fitnessNormal = mapVal(currentFit, 0, maxFitness, 0, 1)
			let n = Math.floor(fitnessNormal**2); // Arbitrary multiplier

			for (let j = 0; j < n; j++) {
				this.matingPool.push(this.population[i])
			}
		}
	}

	// Making the next generation
	reproduction() {
		// Refill the population with children from the mating pool
		for (let i = 0; i < this.population.length; i++) {
			// Sping the wheel of fortune to pick two parents
			let a = Math.floor(Math.random(this.matingPool.length))
			let b = Math.floor(Math.random(this.matingPool.length))
			// Pick two parents
			let parentA = this.matingPool[a]
			let parentB = this.matingPool[b]
			// Get their genes
			let aGenes = parentA.getDNA()
			let bGgenes = parentB.getDNA()
			// Mate their genes
			let sibling = aGenes.crossover(bGgenes)
			// Mutate their genes
			sibling.mutate(this.mutationRate)
			// Fill the new population with the new child
			this.population[i] = new Individual(i, sibling, this.generations)
		}
		this.generations++
	}

	getGenerations() {
		return this.generations
	}
}

export default Population