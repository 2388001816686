<template>
	<div :id="this.id" class="screenshot">
		<div class="order">{{ this.order + 1 }}</div>
	</div>
</template>

<script>
import * as THREE from '/src/assets/js/three.js'
import OrbitControls from '/src/assets/js/OrbitControls.js'
import STLExporter from '/src/assets/js/STLExporter.js'

export default {	
	name : 'Screenshot',
	props : ['id', 'order', 'img', 'size'],
	data(){
	}, 
	mounted () {
		let el = document.getElementById(this.id),
			im = document.createElement('img')

		im.setAttribute('src', this.img)
		el.style.width = this.size
		el.prepend(im)

	},
}
</script>

<style>
	.screenshot img {
		object-fit: scale-down;
		width: 100%;
	}
	.order{
		width: 100%;
		font-size: 1rem;
		text-align: center;
	}
</style>
