import DNA from '/src/assets/js/dna.js'

class Individual {
	constructor(id, dna_, gen_) {
		this.rolloverOn = false
		this.id = id
		this.dna = dna_ 
		this.fitness = 1
		this.gen = gen_

		let prev = document.getElementById(`individual-${id}`)
		
		if (prev != null) {
			prev.remove()
		}
	}

	getFitness() {
		return this.fitness
	}

	getDNA() {
		return this.dna
	}
}

export default Individual