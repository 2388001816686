<template>
	<div :id="this.id" class="chair" v-on:popChanged="update">
		<button class="select-btn" v-on:click="mateClicked">Mate</button>
		<div v-if="this.king == 'true'" class="dropdown">
			<button class="download-btn" v-on:click="toggleOptions">
				Export<span class="material-icons">expand_more</span>
			</button>
			<div :id="this.id+'-dropdown'" class="dropdown-content" :style="'width : ' + this.size + 'px'">
				<div class="dropdown-item-container">
					<button class="dropdown-item" v-on:click="downloadStl" href="#">3D model</button>
					<p>.stl</p>
				</div>
				<div class="dropdown-item-container">
					<button class="dropdown-item" v-on:click="downloadPng" href="#">image</button>
					<p>.png</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as THREE from '/src/assets/js/three.js'
import OrbitControls from '/src/assets/js/OrbitControls.js'
import STLExporter from '/src/assets/js/STLExporter.js'

export default {	
	name : 'chair',
	props : ['id', 'indi', 'size', 'king'], // king indicates if it's the big chair
	data(){
		return {
			width : this.size,
			height : this.size*6/5, 
			seatElevation : Math.round(this.indi.dna.genes[15]) == 1 ? this.mapVal(this.indi.dna.genes[11], 0, 1, 10, 60) + this.mapVal(this.indi.dna.genes[17], 0, 1, 5, 40) + (this.mapVal(this.indi.dna.genes[1], 0, 1, 5, 10) / 2) : this.mapVal(this.indi.dna.genes[11], 0, 1, 10, 60) + (this.mapVal(this.indi.dna.genes[1], 0, 1, 5, 10) / 2)
		}
	}, 
	watch : {
		// When one of these props is modified, something changes in the component
		indi(val) {
			this.seatElevation = Math.round(this.indi.dna.genes[15]) == 1 ? this.mapVal(this.indi.dna.genes[11], 0, 1, 10, 60) + this.mapVal(this.indi.dna.genes[17], 0, 1, 5, 40) + (this.mapVal(this.indi.dna.genes[1], 0, 1, 5, 10) / 2) : this.mapVal(this.indi.dna.genes[11], 0, 1, 10, 60) + (this.mapVal(this.indi.dna.genes[1], 0, 1, 5, 10) / 2)
			this.show()
		},
		size(val) {
			this.width = this.size
			this.height = this.size*6/5
			if (this.king == 'true' && window.innerWidth <= 900) {
				this.height = this.size/6*5
			}

			/*this.camera.updateProjectionMatrix()*/

			this.updateCamera()

			this.renderer.setSize(this.width, this.height)
			this.renderer.setPixelRatio(window.devicePixelRatio)
			
			let canvas = document.querySelector(`#${this.id} canvas`)
			canvas.style.width = `${this.width}px`
			canvas.style.height = `${this.height}px`

			if (window.innerWidth < 768) {
				this.controls.enabled = false
			} else {
				this.controls.enabled = true
			}

			this.renderer.render(this.scene, this.camera)
			this.show()
		},
		height() {
			this.seatElevation = Math.round(this.indi.dna.genes[15]) == 1 ? this.mapVal(this.indi.dna.genes[11], 0, 1, 10, 60) + this.mapVal(this.indi.dna.genes[17], 0, 1, 5, 40) + (this.mapVal(this.indi.dna.genes[1], 0, 1, 5, 10) / 2) : this.mapVal(this.indi.dna.genes[11], 0, 1, 10, 60) + (this.mapVal(this.indi.dna.genes[1], 0, 1, 5, 10) / 2)
			this.updateCamera()
		}
	},
	mounted () {
		const scene = new THREE.Scene(),
			camera = new THREE.OrthographicCamera( this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 1000 ),
			light = new THREE.PointLight( 0xFFFF99 ),
			light2 = new THREE.AmbientLight( 0xFFFFFF ),
			renderer = new THREE.WebGLRenderer( { 
					antialias: true, 
					preserveDrawingBuffer: true,
					alpha : true
					}) ,
			controls = new THREE.OrbitControls( camera, renderer.domElement ),
			target = document.getElementById(this.id)

		light.position.set( 25, 400, 200 )
		light.castShadow = true
		light.distance = 600
		light.power = 125
		light.decay = 2
		light.shadow.mapSize.width = 500
		light.shadow.mapSize.height = 500
		light.shadow.radius = 2

		light.correct = 2

		light2.castShadow = false
		light2.intensity = 0.6
		light2.distance = 0
		light2.power = 20

		light2.correct = 2

		this.light = light
		this.light2 = light2

		renderer.setPixelRatio(window.devicePixelRatio)
		renderer.setSize(this.width, this.height)
		renderer.shadowMap.enabled = true
		renderer.shadowMapSoft = true
		renderer.shadowMap.type = THREE.PCFSoftShadowMap

		target.prepend(renderer.domElement)	

		controls.enableZoom = false
		controls.enablePan = false
		controls.enableDamping = false
		controls.enableRotate = window.innerWidth >= 900 ? true : false

		camera.position.x = -260
		camera.position.y = 300
		camera.position.z = 350

		camera.lookAt(new THREE.Vector3(0, 0, 0))
		
		this.scene = scene
		this.renderer = renderer
		this.camera = camera
		this.controls = controls

		this.updateCamera()

		let that = this

		function animate() {
			requestAnimationFrame( animate )
			that.renderer.render( that.scene, that.camera )
		}
		animate()		
		this.show()
	},
	emits : ['selectedIndividual', 'newScreenshot'],
	methods : {
		constrain : function (n, low, high) {
			return Math.max(Math.min(n, high), low)
		},
		mapVal : function (val, start1, stop1, start2, stop2) {
			let newval = (val - start1) / (stop1 - start1) * (stop2 - start2) + start2
			if (val < start1 || val > stop1) {
				return newval
			}
			if (start2 < stop2) {
				return this.constrain(newval, start2, stop2);
			} else {
				return this.constrain(newval, stop2, start2);
			}
		},
		mateClicked : function () {
			console.log('individual emitting', this.id.replace('indi-', ''))
			this.$emit('selectedIndividual', this.id.replace('indi-', ''))
			this.$emit('newScreenshot', this.renderer.domElement.toDataURL())
		},
		toggleOptions : function () {
			document.getElementById(`${this.id}-dropdown`).classList.toggle('show')
		}, 
		downloadStl : function () {
		
			let ico = this.scene.getObjectByName('icosphere')
			this.scene.remove( ico )
			this.renderer.render( this.scene, this.camera )
			
			
			let exporter = new STLExporter(),
				str = exporter.parse( this.scene ),
				blob = new Blob( [str], { type : 'text/plain' } ),
				link = document.createElement('a')				

			link.style.display = 'none'
			document.body.appendChild(link)
			link.href = URL.createObjectURL(blob)
			link.download = 'your-chair.stl'
			link.click()
			this.toggleOptions()

			this.scene.add( ico )
			this.renderer.render( this.scene, this.camera )
		}, 
		downloadPng : function () {
			let img = this.renderer.domElement.toDataURL(),
				link = document.createElement('a')

			link.download = 'your-chair.png'
			link.href = img
			link.click()
			
			this.toggleOptions()
		}, 
		updateCamera : function() {
			this.camera.aspect = this.width / this.height
			this.camera.top = this.height - (this.height/3)
			this.camera.bottom = - (this.height/3)
			this.camera.left = this.width / - 2 
			this.camera.right = this.width / 2

			if (this.king == 'true') {
				/*this.camera.top = this.height - (this.height/3)
				this.camera.bottom = - (this.height/3)*/
				/*this.camera.top = this.height/2
				this.camera.bottom = -this.height/2*/
				this.camera.top = this.height - (this.height/2.5)
				this.camera.bottom = - (this.height/2.5)
				if (this.width >= this.height) {
					if (this.width >= 580) {
						this.camera.zoom = 2.8
					} else if (this.width < 600 && this.width >= 420) {
						this.camera.top = this.height - (this.height/2.8)
						this.camera.bottom = - (this.height/2.8)
						this.camera.zoom = 2
					} else {
						this.camera.top = this.height - (this.height/3.5)
						this.camera.bottom = - (this.height/3.5)
						this.camera.zoom = 1.3
					}
				} else {
					if (this.width >= 600) {
						this.camera.top = this.height - (this.height/2.45)
						this.camera.bottom = - (this.height/2.45)
						this.camera.zoom = 3.6
					} else if (this.width < 600 && this.width >= 500) {
						this.camera.zoom = 3.4
					} else if (this.width < 500 && this.width >= 400) {
						this.camera.top = this.height - (this.height/2.5)
						this.camera.bottom = - (this.height/2.5)
						this.camera.zoom = 2.9
					} else if (this.width < 400 && this.width >= 370) {
						this.camera.top = this.height - (this.height/2.6)
						this.camera.bottom = - (this.height/2.6)
						this.camera.zoom = 2.6
					} else if (this.width < 370 && this.width >= 320) {
						this.camera.top = this.height - (this.height/2.8)
						this.camera.bottom = - (this.height/2.8)
						this.camera.zoom = 2.2
					} else if (this.width < 320 && this.width >= 250) {
						this.camera.top = this.height - (this.height/3)
						this.camera.bottom = - (this.height/3)
						this.camera.zoom = 2
					} else {
						this.camera.zoom = 1
					}
				}						
			} else {
				if (this.width >= 350) {
					this.camera.zoom = 2
				} else if (this.width < 350 && this.width >= 270) {
					this.camera.zoom = 1.6
				} else if (this.width < 270 && this.width >= 240) {
					this.camera.top = this.height - (this.height/3.5)
					this.camera.bottom = - (this.height/3.5)
					this.camera.zoom = 1.4
				} else if (this.width < 240 && this.width >= 200) {
					this.camera.top = this.height - (this.height/4)
					this.camera.bottom = - (this.height/4)
					this.camera.zoom = 1.2
				} else if (this.width < 200 && this.width >= 170) {
					this.camera.top = this.height - (this.height/6)
					this.camera.bottom = - (this.height/6)
					this.camera.zoom = 1.1
				} else {
					this.camera.top = this.height - (this.height/18)
					this.camera.bottom = - (this.height/18)
					this.camera.zoom = .7
				}
			}

			this.camera.updateProjectionMatrix()

			this.controls.enableRotate = window.innerWidth >= 900 ? true : false
		},
		show : function() {
			this.scene.remove.apply(this.scene, this.scene.children)
			this.scene.add(this.light)
			this.scene.add(this.light2)
			
			let genes = this.indi.dna.genes,
				spawn = ['seat', 'back', 'leg', 'armrest', 'feet'],
				seatElevation = this.seatElevation

			const geneIt = genes[Symbol.iterator]()

			let seatw = this.mapVal(geneIt.next().value, 0, 1, 25, 60),
				seath = this.mapVal(geneIt.next().value, 0, 1, 5, 10),
				seatd = this.mapVal(geneIt.next().value, 0, 1, 25, 60),
				seatx = 0,
				//seaty = 0,
				seaty = seatElevation,
				seatz = 0,
				back = Math.round(geneIt.next().value),
				backw = this.mapVal(geneIt.next().value, 0, 1, 20, 60),
				backh = this.mapVal(geneIt.next().value, 0, 1, 30, 40),
				backd = this.mapVal(geneIt.next().value, 0, 1, 5, 10),
				backhole = Math.round(geneIt.next().value),
				backtw = this.mapVal(geneIt.next().value, 0, 1, seatw, seatw*1.5),
				backth = this.mapVal(geneIt.next().value, 0, 1, 5, 40),
				backx = 0,
				//backy = -(seath/2)+(backh/2),
				backy = seatElevation -(seath/2)+(backh/2),
				backz = - (seatd/2) - (backd/2),
				//backty = backh+(backth/2)-(seath/2),
				backty = seatElevation + backh + (backth/2) - (seath/2),
				backbx = (seatw/2)-(backd/2),
				legw = this.mapVal(geneIt.next().value, 0, 1, 3, 10),
				legh = this.mapVal(geneIt.next().value, 0, 1, 10, 60),
				legd = this.mapVal(geneIt.next().value, 0, 1, 3, 10),
				leg1x = this.mapVal(geneIt.next().value, 0, 1, seatw/8, seatw/2),
				//leg1y = -(seath/2)-(legh/2), 
				leg1y = seatElevation - (seath/2) - (legh/2), 
				leg1z = this.mapVal(geneIt.next().value, 0, 1, seatd/8, seatd/2),
				leg2x = -leg1x,
				leg2y = leg1y, 
				leg2z = -leg1z,
				foot = Math.round(geneIt.next().value),
				footw = this.mapVal(geneIt.next().value, 0, 1, 1, 20),
				footh = this.mapVal(geneIt.next().value, 0, 1, 5, 40),
				footd = this.mapVal(geneIt.next().value, 0, 1, legd, 60),
				footy = leg1y - (legh/2) - (footh/2),
				circ = Math.round(geneIt.next().value),
				circr = this.mapVal(geneIt.next().value, 0, 1, 8, 20),
				circwhich = Math.floor(this.mapVal(geneIt.next().value, 0, 1, 0, 4)),
				circx = geneIt.next().value,
				circy = geneIt.next().value,
				circz = geneIt.next().value,
				circseg = 10,
				arm = Math.round(geneIt.next().value),
				armw = this.mapVal(geneIt.next().value, 0, 1, 5, 10), 
				armh = this.mapVal(geneIt.next().value, 0, 1, 5, 10), 
				armd = seatd, 
				armverh = this.mapVal(geneIt.next().value, 0, 1, 5, 60), 
				armverd = armw, 
				armx = seatw/2 - armw/2,
				//armvery = (seath/2) + (armverh/2),
				armvery = seatElevation + (seath/2) + (armverh/2),
				//army = (seath/2) + armverh + (armh/2),
				army = seatElevation + (seath/2) + armverh + (armh/2),
				armz = seatd/2 - armverd/2

			let palette = [0x51FFBC, 0xFF6E4F, 0xB64AFF, 0x58B9F8, 0x5551FF, 0xFFDC5F]

			let cAdd = palette[0],
				cSeat = palette[4],
				cFoot = palette[3],
				cLeg = palette[2],
				cRest = palette[1],
				cBack = palette[5]				

			const group = new THREE.Group()

			/*XZ axis*/
			/*const axisGeom = new THREE.BoxGeometry()
			const axisMaterial = new THREE.MeshLambertMaterial( { color: cSeat} )
			const axis = new THREE.Mesh( axisGeom, axisMaterial )
			axis.castShadow = true
			axis.receiveShadow = true
			this.scene.add( axis )
			axis.geometry.scale( 50, 1, 50 )
			axis.position.set(0, 0, 0)
*/
			/*SEAT*/
			const seatGeom = new THREE.BoxGeometry()
			const seatMaterial = new THREE.MeshLambertMaterial( { color: cSeat} )
			const seat = new THREE.Mesh( seatGeom, seatMaterial )
			seat.castShadow = true
			seat.receiveShadow = true
			this.scene.add( seat )
			seat.geometry.scale( seatw, seath, seatd )
			seat.position.set(seatx, seaty, seatz)

			/*BACK*/
			if (back == 1 && backhole == 0) {
				const backGeom = new THREE.BoxGeometry()
				const backMaterial = new THREE.MeshLambertMaterial( { color: cBack } )
				const back = new THREE.Mesh( backGeom, backMaterial )
				back.castShadow = true
				back.receiveShadow = true
				this.scene.add( back )
				back.geometry.scale( backw, backh, backd )
				back.position.set(backx, backy, backz)
			} else if (back == 1 && backhole == 1) {
				// right bar
				const back1Geom = new THREE.BoxGeometry()
				const back1Material = new THREE.MeshLambertMaterial( { color: cBack } )
				const back1 = new THREE.Mesh( back1Geom, back1Material )
				back1.castShadow = true
				back1.receiveShadow = true
				this.scene.add( back1 )
				back1.geometry.scale( backd, backh, backd )
				back1.position.set(backbx, backy, backz)
				// left bar
				const back2Geom = new THREE.BoxGeometry()
				const back2Material = new THREE.MeshLambertMaterial( { color: cBack } )
				const back2 = new THREE.Mesh( back2Geom, back2Material )
				back2.castShadow = true
				back2.receiveShadow = true
				this.scene.add( back2 )
				back2.geometry.scale( backd, backh, backd )
				back2.position.set(-backbx, backy, backz)
				// horizontal bar
				const backGeom = new THREE.BoxGeometry()
				const backMaterial = new THREE.MeshLambertMaterial( { color: cBack } )
				const back = new THREE.Mesh( backGeom, backMaterial )
				back.castShadow = true
				back.receiveShadow = true
				this.scene.add( back )
				back.geometry.scale( backtw, backth, backd )
				back.position.set(backx, backty, backz)
			}

			/*LEGS*/
			// leg1
			const leg1Geom = new THREE.BoxGeometry()
			const leg1Material = new THREE.MeshLambertMaterial( { color: cLeg } )
			const leg1 = new THREE.Mesh( leg1Geom, leg1Material )
			leg1.castShadow = true
			leg1.receiveShadow = true
			this.scene.add( leg1 )
			leg1.geometry.scale( legw, legh, legd )
			leg1.position.set( leg1x, leg1y, leg1z )
			// leg2
			const leg2Geom = new THREE.BoxGeometry()
			const leg2Material = new THREE.MeshLambertMaterial( { color: cLeg } )
			const leg2 = new THREE.Mesh( leg2Geom, leg2Material )
			leg2.castShadow = true
			leg2.receiveShadow = true
			this.scene.add( leg2 )
			leg2.geometry.scale( legw, legh, legd )
			leg2.position.set( leg2x, leg2y, leg2z )
			// leg3
			const leg3Geom = new THREE.BoxGeometry()
			const leg3Material = new THREE.MeshLambertMaterial( { color: cLeg } )
			const leg3 = new THREE.Mesh( leg3Geom, leg3Material )
			leg3.castShadow = true
			leg3.receiveShadow = true
			this.scene.add( leg3 )
			leg3.geometry.scale( legw, legh, legd )
			leg3.position.set( leg1x, leg1y, leg2z )
			// leg4
			const leg4Geom = new THREE.BoxGeometry()
			const leg4Material = new THREE.MeshLambertMaterial( { color: cLeg } )
			const leg4 = new THREE.Mesh( leg4Geom, leg4Material )
			leg4.castShadow = true
			leg4.receiveShadow = true
			this.scene.add( leg4 )
			leg4.geometry.scale( legw, legh, legd )
			leg4.position.set( leg2x, leg2y, leg1z )

			/* CIRCLE */
			if (circ == 1) {
				let x = circx,
					y = circy,
					z = circz

				circx = null
				circy = null
				circz = null

				switch (spawn[circwhich]) {
					case 'seat':
						circx = this.mapVal(x, 0, 1, -seatw/2, seatw/16)
						circy = this.mapVal(y, 0, 1, seatElevation-seath/2, seatElevation-seath/17)
						circz = this.mapVal(z, 0, 1, -seatd/2, seatd/2)
						break
					case 'back':
						if (back == 1) {
							if (backhole == 0) {
								circx = this.mapVal(x, 0, 1, -backw/2, backw/2)
								circy = this.mapVal(y, 0, 1, backy, backy+backy/2)
								circz = this.mapVal(z, 0, 1, backz-circr/3, backz+circr/3)
							} else {
								circx = this.mapVal(x, 0, 1, -backtw/2, backtw/2)
								circy = this.mapVal(y, 0, 1, backty-backth/2, backty+backth/2)
								circz = this.mapVal(z, 0, 1, backz-circr/3, backz+circr/3)
							}
						}
						break
					case 'leg':
						switch (circwhich) {
							case 0:
								circx = this.mapVal(x, 0, 1, leg1x)
								circy = this.mapVal(y, 0, 1, circr, leg1y+(legh/2))
								circz = this.mapVal(z, 0, 1, leg1z)
								break
							case 1:
								circx = this.mapVal(x, 0, 1, leg1x)
								circy = this.mapVal(y, 0, 1, circr, leg1y+(legh/2))
								circz = this.mapVal(z, 0, 1, -leg1z)
								break
							case 2:
								circx = this.mapVal(x, 0, 1, leg2x)
								circy = this.mapVal(y, 0, 1, circr, leg2y+(legh/2))
								circz = this.mapVal(z, 0, 1, leg2z)
								break
							case 3:
								circx = this.mapVal(x, 0, 1, leg2x)
								circy = this.mapVal(y, 0, 1, circr, leg2y+(legh/2))
								circz = this.mapVal(z, 0, 1, -leg2z)
								break
							default:
								break
						}
						break
					case 'armrest':
						switch (circwhich) {
							case (0 || 1):
								circx = this.mapVal(x, 0, 1, armx)
								circy = this.mapVal(y, 0, 1, seatElevation +army - circr)
								circz = this.mapVal(z, 0, 1, leg1z)
								break
							case (2 || 3):
								circx = this.mapVal(x, 0, 1, -armx)
								circy = this.mapVal(y, 0, 1, seatElevation +army - circr)
								circz = this.mapVal(z, 0, 1, -armz/2, armz/2)
								break
							default:
								break
						}
						break
					case 'feet':
						switch (circwhich) {
							case 0:
								circx = this.mapVal(x, 0, 1, leg1x)
								circy = this.mapVal(y, 0, 1, circr, footy+(footh/2))
								circz = this.mapVal(z, 0, 1, leg1z-(footd/2), leg1z+(footd/2))
								break
							case 1:
								circx = this.mapVal(x, 0, 1, leg1x)
								circy = this.mapVal(y, 0, 1, circr, leg1y+(legh/2))
								circz = this.mapVal(z, 0, 1, -leg1z-(footd/2), -leg1z+(footd/2))
								break
							case 2:
								circx = this.mapVal(x, 0, 1, leg2x)
								circy = this.mapVal(y, 0, 1, circr, footy+(footh/2))
								circz = this.mapVal(z, 0, 1, leg2z-(footd/2), leg2z+(footd/2))
								break
							case 3:
								circx = this.mapVal(x, 0, 1, leg2x)
								circy = this.mapVal(y, 0, 1, circr, footy+(footh/2))
								circz = this.mapVal(z, 0, 1, -leg2z-(footd/2), -leg2z+(footd/2))
								break
							default:
								break
						}
						break
					default:
						break
				}

				if (circx != null) {
					const circGeom = new THREE.IcosahedronGeometry(circr, 0)
					circGeom.name = 'icosphereGeom'
					const circMaterial = new THREE.MeshLambertMaterial( { color: cAdd  } )
					const circ = new THREE.Mesh( circGeom, circMaterial )
					circ.castShadow = true
					circ.receiveShadow = true
					this.scene.add( circ )
					circ.position.set( circx, circy, circz )
					circ.name = 'icosphere'
				}
			}

			/* FEET */

			if (foot == 1) {
				// foot1
				const foot1Geom = new THREE.BoxGeometry()
				const foot1Material = new THREE.MeshLambertMaterial( { color: cFoot } )
				const foot1 = new THREE.Mesh( foot1Geom, foot1Material )
				foot1.castShadow = true
				foot1.receiveShadow = true
				this.scene.add( foot1 )
				foot1.geometry.scale( footw, footh, footd )
				foot1.position.set( leg1x, footy, leg1z )
				// foot2
				const foot2Geom = new THREE.BoxGeometry()
				const foot2Material = new THREE.MeshLambertMaterial( { color: cFoot } )
				const foot2 = new THREE.Mesh( foot2Geom, foot2Material )
				foot1.castShadow = true
				foot1.receiveShadow = true
				this.scene.add( foot2 )
				foot2.geometry.scale( footw, footh, footd )
				foot2.position.set( leg2x, footy, leg2z )
				// foot3
				const foot3Geom = new THREE.BoxGeometry()
				const foot3Material = new THREE.MeshLambertMaterial( { color: cFoot } )
				const foot3 = new THREE.Mesh( foot3Geom, foot3Material )
				foot3.castShadow = true
				foot3.receiveShadow = true
				this.scene.add( foot3 )
				foot3.geometry.scale( footw, footh, footd )
				foot3.position.set( leg1x, footy, leg2z )
				// foot4
				const foot4Geom = new THREE.BoxGeometry()
				const foot4Material = new THREE.MeshLambertMaterial( { color: cFoot } )
				const foot4 = new THREE.Mesh( foot4Geom, foot4Material )
				foot4.castShadow = true
				foot4.receiveShadow = true
				this.scene.add( foot4 )
				foot4.geometry.scale( footw, footh, footd )
				foot4.position.set( leg2x, footy, leg1z )
			}

			/* ARMREST */
			if (arm == 1) {
				// dx front bar
				const arm1frontGeom = new THREE.BoxGeometry()
				const arm1frontMaterial = new THREE.MeshLambertMaterial( { color: cRest } )
				const arm1front = new THREE.Mesh( arm1frontGeom, arm1frontMaterial )
				arm1front.castShadow = true
				arm1front.receiveShadow = true
				this.scene.add( arm1front )
				arm1front.geometry.scale( armw, armverh, armverd )
				arm1front.position.set( armx, armvery, armz )

				// dx back bar
				const arm1backGeom = new THREE.BoxGeometry()
				const arm1backMaterial = new THREE.MeshLambertMaterial( { color: cRest } )
				const arm1back = new THREE.Mesh( arm1backGeom, arm1backMaterial )
				arm1back.castShadow = true
				arm1back.receiveShadow = true
				this.scene.add( arm1back )
				arm1back.geometry.scale( armw, armverh, armverd )
				arm1back.position.set( armx, armvery, -armz )

				// dx hor bar
				const arm1horGeom = new THREE.BoxGeometry()
				const arm1horMaterial = new THREE.MeshLambertMaterial( { color: cRest } )
				const arm1hor = new THREE.Mesh( arm1horGeom, arm1horMaterial )
				arm1hor.castShadow = true
				arm1hor.receiveShadow = true
				this.scene.add( arm1hor )
				arm1hor.geometry.scale( armw, armh, armd )
				arm1hor.position.set( armx, army, 0 )


				// sx front bar
				const arm2frontGeom = new THREE.BoxGeometry()
				const arm2frontMaterial = new THREE.MeshLambertMaterial( { color: cRest } )
				const arm2front = new THREE.Mesh( arm2frontGeom, arm2frontMaterial )
				arm2front.castShadow = true
				arm2front.receiveShadow = true
				this.scene.add( arm2front )
				arm2front.geometry.scale( armw, armverh, armverd )
				arm2front.position.set( -armx, armvery, armz )

				// sx back bar
				const arm2backGeom = new THREE.BoxGeometry()
				const arm2backMaterial = new THREE.MeshLambertMaterial( { color: cRest } )
				const arm2back = new THREE.Mesh( arm2backGeom, arm2backMaterial )
				arm2back.castShadow = true
				arm2back.receiveShadow = true
				this.scene.add( arm2back )
				arm2back.geometry.scale( armw, armverh, armverd )
				arm2back.position.set( -armx, armvery, -armz )

				// wx hor bar
				const arm2horGeom = new THREE.BoxGeometry()
				const arm2horMaterial = new THREE.MeshLambertMaterial( { color: cRest } )
				const arm2hor = new THREE.Mesh( arm2horGeom, arm2horMaterial )
				arm2hor.castShadow = true
				arm2hor.receiveShadow = true
				this.scene.add( arm2hor )
				arm2hor.geometry.scale( armw, armh, armd )
				arm2hor.position.set( -armx, army, 0 )
			}

			this.updateCamera()
		}
	}

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.chair {
		padding: 12px 0;
		text-align: center;
		align-items:center;
	}
	.chair canvas {
		flex-grow: 0;
		/*margin-bottom: 12px;*/
	}
	.select-btn {
		margin-top: 12px;
	}
	.download-btn {
		display: none;
	}
	.download-btn {
		display: none;
	}
	.download-btn:hover {
		background-color: #5551FF
	}
	.dropdown-content {
		display: none;
		z-index: 1;
		position: absolute;
	}
	.dropdown-content.show {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.dropdown-item-container {
		padding: 4px;
	}
	.dropdown-content button {
		display: block;
	}
	.dropdown-content button:hover {
		background-color: #5551FF;
		color: #FFF;
	}
	button.material-icons {
		font-size: .8rem;
	}
	@media only screen and (max-width: 900px) {
		.download-btn {
			padding: .2rem .74rem .4rem;
		}
	}
	@media only screen and (max-width: 768px) {
		.chair canvas {
			pointer-events: none;
			user-drag: none;
				-webkit-user-drag: none;
				user-select: none;
				-moz-user-select: none;
				-webkit-user-select: none;
				-ms-user-select: none;
		}
	}
	@media only screen and (max-width: 550px) {
		.download-btn {
			padding: .4rem .74rem .2rem 1.2rem;
		}
	}
</style>
