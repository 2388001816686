<template>
  <header>
    <h1 id="page-title">EVOLVING<br />FURNITURE</h1>
  </header>
  <p id="subtitle">
    An algorithmic appendix of
    <a href="https://everydayexperiments.com/updatables" target="blank"
      >Updatables</a
    >,<br />
    an
    <a href="https://everydayexperiments.com/updatables" target="blank"
      >Everyday Experiment</a
    >
    designed by <a href="https://oio.studio/">oio</a>,<br />
    curated by <a href="https://space10.com/" target="blank">Space10</a>
  </p>
  <div id="first-section" class="section big-margin">
    <div id="first-section-text" class="grid-content grid-big">
      <h3>This chair is designed by an algorithm</h3>
      <p>
        What if we could evolve products across generations, just like living
        beings on earth?
      </p>
      <p>
        We design these chairs using a genetic algorithm, a branch of artificial
        intelligence simulating the natural evolutionary process.
      </p>
      <a
        href="https://everydayexperiments.com/updatables"
        class="button-link"
        target="blank"
        >More info</a
      >
    </div>
    <div class="grid-content grid-small">
      <img src="@/assets/img/this_chair.webp" />
    </div>
  </div>
  <div class="section">
    <div class="grid-content grid-full">
      <img id="dna-img" class="smaller-img" src="@/assets/img/dna.png" />
      <h3>How does it work?</h3>
      <p>
        Every feature of the chair (color, size, etc) is defined by a gene. All
        the genes together form the genome of the piece of furniture, that is
        passed down to the following generations.
      </p>
      <br />
      <p>Try it!</p>
      <br />
      <p>
        <img src="@/assets/img/arrow.svg" />
      </p>
    </div>
  </div>
  <div class="section medium-margin">
    <h2 id="playground-title">ALGORITHMIC PLAYGROUND</h2>
    <div class="grid-content grid-full">
      <p>
        Select a potential mate for your chair from the pool below, then see how
        that affects the next generation. After every update the pool is filled
        with a new cohort of potential evolutionary mates.
      </p>
    </div>
  </div>
  <div v-if="!isMobile()" id="scenes-container">
    <div id="king-container">
      <div class="scene-title">
        <h4>Your chair</h4>
        <p class="secondary">
          Generation #{{ generation }}
          <button id="reset-btn" v-on:click="resetPop">Reset</button>
        </p>
      </div>
      <Chair
        v-bind:indi="this.king"
        v-bind:id="'indi-' + 0"
        v-bind:size="this.kingSize"
        king="true"
      />
    </div>
    <div id="plus">
      <img src="@/assets/img/plus.svg" />
    </div>
    <div id="pop-container">
      <div class="scene-title">
        <h4>Mating Pool</h4>
        <p class="secondary">Potential mates for your chair.</p>
      </div>
      <div id="scenes">
        <Chair
          @selectedIndividual="evolve"
          @newScreenshot="addScreenshot"
          v-for="(indi, key) in this.indis"
          v-bind:indi="indi"
          v-bind:id="'indi-' + (key + 1)"
          v-bind:size="this.indiSize"
          king="false"
        />
      </div>
    </div>
  </div>
  <div v-else id="scenes-container-mobile">
    <div id="king-container-mobile">
      <div class="scene-title">
        <h4>Your chair</h4>
        <p class="secondary">
          Generation #{{ generation }}
          <button id="reset-btn" v-on:click="resetPop">Reset</button>
        </p>
      </div>
      <Chair
        v-bind:indi="this.king"
        v-bind:id="'indi-' + 0"
        v-bind:size="this.kingSize"
        king="true"
      />
    </div>
    <div id="pop-container-mobile">
      <div class="scene-title">
        <h4>Mating Pool</h4>
        <p class="secondary">Potential mates for your chair.</p>
      </div>
      <div id="scenes">
        <Chair
          @selectedIndividual="evolve"
          @newScreenshot="addScreenshot"
          v-for="(indi, key) in this.indis"
          v-bind:indi="indi"
          v-bind:id="'indi-' + (key + 1)"
          v-bind:size="this.indiSize"
          king="false"
        />
      </div>
    </div>
  </div>
  <div v-if="this.screenshots.length > 0" id="history-container">
    <h5>Chair generations</h5>
    <Screenshot
      v-for="(img, key) in this.screenshots"
      v-bind:id="'screenshot-' + key"
      v-bind:order="key"
      v-bind:img="img"
      v-bind:size="screenshotSize"
    />
  </div>
  <div class="break">
    <img src="@/assets/img/wave.svg" />
  </div>
  <div class="section large big-margin">
    <div class="grid-content grid-half">
      <img id="shell-img" src="./assets/img/shell.png" />
      <h3>Evolving circularity</h3>
      <p>
        This is not just a technical experiment - the ultimate goal of
        Updatables is to reduce waste by finding new meaning for outdated
        objects, rather than <br class="orphan" />
        disposing of them.
      </p>
      <a href="https://everydayexperiments.com/updatables" target="blank"
        >More info</a
      >
    </div>
    <div class="grid-content grid-half">
      <img id="blister-img" src="./assets/img/blister.png" />
      <h3>Just an experiment</h3>
      <p>
        Far from an accurate scientific simulation, this experiment is used to
        depict a possible future scenario where previously inanimate objects are
        evolving with us.
      </p>
      <a href="https://everydayexperiments.com/updatables">More info</a>
    </div>
  </div>
  <div class="break">
    <img src="@/assets/img/wave.svg" />
  </div>
  <img
    v-if="!isMobile() && isBigScreen()"
    id="curbed-arrow"
    src="@/assets/img/curbed_arrow.svg"
  />
  <img id="icosphere" src="@/assets/img/icosphere.png" />
  <img id="box" src="@/assets/img/box.png" />
  <img
    v-if="!isMobile()"
    id="playground-icosphere"
    src="@/assets/img/icosphere.png"
  />
  <img v-if="!isMobile()" id="playground-box" src="@/assets/img/box.png" />
  <footer>
    <div id="questions">
      <h3>Questions?</h3>
      <p><a href="mailto:hello@oio.studio" target="blank">Get in touch</a></p>
    </div>
    <div id="oio-logo">
      <a href="https://oio.studio/" target="blank"
        ><img src="@/assets/img/oio.svg"
      /></a>
    </div>
  </footer>
</template>

<script>
import DNA from "/src/assets/js/dna.js";
import Individual from "/src/assets/js/individual.js";
import Population from "/src/assets/js/population.js";
import Chair from "./components/chair.vue";
import Screenshot from "./components/screenshot.vue";

const simple_chair = [
  //seat
  0.55, 0.5, 0.6,
  //back
  1, 0.8, 0.9, 0.6,
  // backhole
  1, 0.25, 0.6,
  //leg
  0.6, 0.4, 0.6, 0.734, 0.734,
  //foot
  1, 0.7, 0.1, 0.1,
  //circ
  1, 0.3, 0.3, 1, 0.5, 1,
  //arm
  1, 0.6, 0, 0.15,
];

export default {
  name: "App",
  components: {
    Chair,
    Screenshot,
  },
  emits: ["popChanged"],
  data() {
    return {
      popN: 7,
      mutationRate: 0.2,
      population: null,
      king: null,
      kingSize: null,
      indis: [],
      indiSize: null,
      generation: 0,
      screenshots: [],
      screenshotSize: null,
      imgs: [],
      needBr: false,
    };
  },
  created() {
    this.population = new Population(this.mutationRate, this.popN);
    this.population.population[0].dna = new DNA(simple_chair);
    this.king = this.population.population[0];
    this.indis = this.population.population.slice(1);
    this.kingSize = 200;
    this.indiSize = 100;
  },
  mounted() {
    window.addEventListener("resize", this.updateSizes);
    this.updateSizes();
  },
  methods: {
    evolve: function (indiv) {
      /*console.log('evolving', indiv)*/
      this.generation++;
      this.population.selection(indiv);
      this.population.reproduction();
      this.king = this.population.population[0];
      this.indis = this.population.population.slice(1);
    },
    resetPop: function () {
      this.generation = 0;
      this.population = new Population(this.mutationRate, this.popN);
      this.population.population[0].dna = new DNA(simple_chair);
      this.king = this.population.population[0];
      this.indis = this.population.population.slice(1);
      this.screenshots = [];
    },
    addScreenshot: function (img) {
      this.screenshots.push(img);
    },
    isMobile: function () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        window.innerWidth <= 780
      ) {
        return true;
      } else {
        return false;
      }
    },
    isBigScreen: function () {
      return window.innerWidth > 1024;
    },
    getOffset: function (el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + (rect.width / 5) * 4 + window.scrollX,
        top: rect.top + (rect.height / 3) * 2 + window.scrollY,
      };
    },
    getOffsetMedium: function (el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + rect.width + window.scrollX,
        top: rect.top + (rect.height / 3) * 2 + window.scrollY,
      };
    },
    getOffsetSmall: function (el) {
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + rect.width + window.scrollX,
        top: rect.top + window.scrollY,
      };
    },
    getOffsetTitle: function () {
      let el = document.getElementById("page-title");
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + rect.width + window.scrollX,
        top: rect.top + (rect.height / 3) * 2 + window.scrollY,
      };
    },
    getOffsetPlayground: function () {
      let el = document.getElementById("playground-title");
      const rect = el.getBoundingClientRect();
      return {
        left: rect.left + rect.width + window.scrollX,
        top: rect.top + (rect.height / 3) * 2 + window.scrollY,
      };
    },
    updateSizes: function () {
      try {
        try {
          this.kingSize = document
            .getElementById("king-container")
            .getBoundingClientRect().width;
          this.indiSize =
            document.getElementById("pop-container").getBoundingClientRect()
              .width / 3.2; /*-12*/
        } catch (e) {
          this.kingSize = document
            .getElementById("king-container-mobile")
            .getBoundingClientRect().width;
          this.indiSize =
            document
              .getElementById("pop-container-mobile")
              .getBoundingClientRect().width / 3.2; /*-12*/
        }

        let curbedArrow = null,
          icosphere = document.getElementById("icosphere"),
          box = document.getElementById("box"),
          playgroundIcosphere = document.getElementById("playground-icosphere"),
          playgroundBox = document.getElementById("playground-box"),
          title = document.getElementById("page-title"),
          playground = document.getElementById("playground-title"),
          firstSec = document.getElementById("first-section-text");

        if (!this.isMobile() && this.isBigScreen()) {
          curbedArrow = document.getElementById("curbed-arrow");
          let arrowY = this.getOffset(firstSec).top + "px";
          curbedArrow.style.top = arrowY;
        }

        this.screenshotSize = "10%";

        let titleOffset = this.getOffsetTitle(),
          playgroundOffset = this.getOffsetPlayground(),
          icosphereY = titleOffset.top,
          boxY = titleOffset.top,
          icosphereX = titleOffset.left,
          boxX = titleOffset.left;

        icosphere.style.top = icosphereY - title.offsetHeight / 2 + "px";
        box.style.top = boxY + title.offsetHeight / 8 + "px";
        icosphere.style.left =
          icosphereX - title.offsetWidth - icosphere.offsetWidth / 2 + "px";
        box.style.left = boxX - box.offsetWidth / 2 + "px";

        if (window.innerWidth >= 768) {
          let playgroundIcosphereY = playgroundOffset.top,
            playgroundBoxY = playgroundOffset.top,
            playgroundIcosphereX = playgroundOffset.left,
            playgroundBoxX = playgroundOffset.left;

          playgroundIcosphere.style.top = playgroundIcosphereY + "px";
          playgroundBox.style.top =
            playgroundBoxY - playground.offsetHeight / 1.5 + "px";
          playgroundIcosphere.style.left =
            playgroundIcosphereX - playgroundIcosphere.offsetWidth / 2 + "px";
          playgroundBox.style.left =
            playgroundBoxX -
            playground.offsetWidth -
            playgroundBox.offsetWidth / 2 +
            "px";
        }

        if (window.innerWidth > 1120) {
          this.needBr = true;

          /*if (!this.isMobile()) {
						let arrowY = this.getOffset(firstSec).top + 'px'
						curbedArrow.style.top = arrowY
					}*/

          if (window.innerWidth >= 1800) {
            this.needBr = false;
          }
        } else if (window.innerWidth > 900) {
          this.needBr = true;

          if (window.innerWidth >= 1800) {
            this.needBr = false;
          }
        } else if (window.innerWidth < 768) {
          this.needBr = false;
          icosphere.style.top =
            icosphereY - (title.offsetHeight / 6) * 5 + "px";
          box.style.top =
            boxY + title.offsetHeight / 8 - box.offsetHeight / 4 + "px";
        } else {
          this.needBr = false;
        }

        document.getElementById("blister-img").style.maxHeight =
          document.getElementById("shell-img").style.height;
      } catch (err) {
        console.log(err); //'nobody cares')
      }
    },
  },
};
</script>

<style>
#app {
  padding: 80px 4.4vw;
}
.secondary {
  font-size: 0.7rem;
}
.section {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 9vw 0;
}
.section.big-margin {
  margin-bottom: 142px;
}
.section.medium-margin {
  margin-bottom: 72px;
}
.section.large {
  padding: 24px 12vw;
}
.grid-full {
  padding: 0 16vw;
  text-align: center;
}
.grid-full p {
  text-align: center;
  max-width: 682px;
}
.grid-big h3,
.grid-small h3 {
  text-align: left;
}
.grid-big p {
  max-width: 520px;
}
.grid-big {
  width: 73%;
  max-width: 680px;
}
.grid-small {
  width: 27%;
  padding-left: 6%;
}
.grid-half {
  width: 50%;
  text-align: center;
  align-self: flex-start;
}
.grid-half h3 {
  padding: 0 0.2vw;
}
.section :first-child.grid-half {
  padding-right: 8%;
}
.section :last-child.grid-half {
  padding-left: 8%;
}
.grid-small img {
  object-fit: contain;
  width: 100%;
}
#dna-img {
  max-width: 172px;
  margin-bottom: 36px;
}
.break {
  padding: 24px;
  text-align: center;
  color: #ffdc5f;
  vertical-align: middle;
}
.scene-title {
  width: 100%;
  text-align: center;
}
.scene-title div {
  display: block;
}
.orphan {
  display: block;
}
#page-title {
  text-align: center;
}
#subtitle {
  line-height: 1.53;
  text-align: center;
  padding: 40px 28vw;
  margin-bottom: 138px;
}
#curbed-arrow,
#icosphere,
#box {
  display: block;
  position: absolute;
  z-index: 100;
}
#curbed-arrow {
  left: 53vw;
  width: 10vw;
  max-width: 130px;
}
#icosphere {
  left: 20vw;
  width: 10vw;
  max-width: 130px;
}
#box {
  left: 72vw;
  width: 10vw;
  max-width: 130px;
}
#playground-icosphere,
#playground-box {
  position: absolute;
  z-index: 90;
  width: 5vw;
  max-width: 100px;
}
#reset-btn {
  margin-left: 16px;
}
#scenes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 64px 8vw;
}
#king-container {
  width: 38%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
}
#plus {
  width: 6%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}
#plus img {
  object-fit: contain;
  align-self: center;
}
#pop-container {
  width: 56%;
}
#scenes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#king-container-mobile {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#pop-container-mobile {
  width: 100%;
}
#indi-0 .select-btn {
  display: none;
}
#indi-0 .download-btn {
  display: inline-block;
}
#history-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 24px 14vw;
}
#history-container h4 {
  margin-bottom: 1rem;
}
#history-container .screenshot:last-child {
  margin-right: 0;
}
#questions {
  margin-top: 94px;
  margin-bottom: 120px;
}
#questions h3 {
  margin-bottom: 0;
}

@media only screen and (min-width: 2100px) {
  .orphan {
    display: none;
  }
  .grid-big p {
    max-width: 540px;
  }
  .grid-small {
    padding-left: 4%;
  }
}

@media only screen and (max-width: 2100px) {
  #curbed-arrow {
    left: 54vw;
  }
  .grid-content.grid-small {
    padding-left: 2%;
  }
}

@media only screen and (min-width: 1620px) and (max-width: 1920px) {
  .orphan {
    display: none;
  }
  .grid-content.grid-small {
    padding-left: 2%;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1850px) {
  .section.large {
    padding: 24px 4vw;
  }
  .grid-big p {
    max-width: 560px;
  }
  .grid-content.grid-small {
    padding-left: 2%;
  }
  .section.large {
    padding: 0 8vw;
  }
  .section :first-child.grid-half {
    padding-left: 3%;
    padding-right: 7%;
  }
  .section :last-child.grid-half {
    padding-left: 3%;
    padding-left: 67;
  }
}

@media only screen and (max-width: 1300px) {
  #subtitle {
    padding: 24px 18vw;
    margin-bottom: 56px;
  }
  #curbed-arrow {
    left: 60vw;
  }
  #first-section-text h3 {
    padding-right: 20%;
  }
  .orphan {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .section.big-margin {
    margin-bottom: 80px;
  }
  .secondary {
    font-size: 1rem;
  }
  .grid-half {
    width: 100%;
  }
  .section :first-child.grid-half {
    padding: 0 6vw 64px;
  }
  .section :last-child.grid-half {
    padding: 0 8vw;
  }
  #curbed-arrow {
    left: 63vw;
  }
  #first-section-text h3 {
    padding-right: 10%;
  }
  #scenes-container {
    justify-content: center;
    padding: 24px 8vw;
  }
  #pop-container {
    width: 100%;
    justify-content: center;
  }
  #pop-container-mobile {
    width: 100%;
    justify-content: center;
  }
  #plus {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  #app {
    padding: 6vw;
    width: 100vw;
  }
  .grid-big {
    width: 64%;
  }
  .grid-small {
    width: 36%;
  }
  .grid-full {
    padding: 0 7vw;
  }
  .section.large {
    padding: 24px 12vw;
  }
  #subtitle {
    font-size: 1.2rem;
    padding: 12px 10vw;
    margin-bottom: 32px;
  }
  #first-section-text h3 {
    padding-right: 0%;
  }
  #king-container {
    width: 100%;
    /*margin-left: 15%;*/
  }
  #king-container-mobile {
    width: 70%;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 850px) {
  .grid-big {
    width: 68%;
  }
  .grid-small {
    width: 32%;
  }
}

@media only screen and (max-width: 768px) {
  #app {
    padding: 3vw;
    padding-bottom: 64px;
    width: 100vw;
  }
  #subtitle {
    padding: 12px 5vw;
  }
  .section {
    padding: 12px 4vw;
  }
  .section.large {
    padding: 24px 6.9vw;
  }
  .section.big-margin {
    margin-bottom: 32px;
  }
  .section.medium-margin {
    margin-bottom: 12px;
  }
  .section :first-child.grid-half {
    padding-bottom: 32px;
    padding-left: 4.5vw;
    padding-right: 4.5vw;
  }
  .section :last-child.grid-half {
    padding-bottom: 0;
    padding-left: 4.5vw;
    padding-right: 4.5vw;
  }
  .section .grid-full {
    padding: 0 6vw;
  }
  #dna-img {
    /*max-width: 96px;*/
    width: 18vw;
    max-width: 120px;
    margin-bottom: 24px;
  }
  #scenes-container {
    justify-content: center;
    padding: 24px 0vw;
  }
  #scenes-container-mobile {
    justify-content: center;
    padding: 24px 0vw;
  }
  #plus {
    display: none;
  }
  #history-container {
    padding: 24px 6vw 64px;
  }
  #icosphere,
  #box {
    width: 12vw;
  }
  #playground-icosphere,
  #playground-box {
    display: none;
  }
  #questions {
    margin-top: 48px;
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 550px) {
  #app {
    padding: 48px 0px 48px;
    width: 100vw;
  }
  .grid-big {
    width: 62%;
  }
  .grid-small {
    width: 38%;
  }
  .section.large {
    padding: 24px 4vw;
  }
  .grid-full {
    padding: 0 2vw;
    max-width: 400px;
  }
  .grid-half {
    max-width: 380px;
  }
  .grid-content img {
    max-width: 160px;
  }
  .smaller-img {
    max-width: 80px;
  }
  #subtitle {
    font-size: 1.2rem;
    padding: 12px 2vw;
    margin-bottom: 32px;
  }
  #questions {
    margin-bottom: 24px;
  }
}
</style>
