class DNA {
	constructor(newgenes) {
		let len = 30
		if (newgenes) {
			this.genes = newgenes
		} else {
			this.genes = new Array(len)
			for (let i = 0; i < this.genes.length; i++) {
				this.genes[i] = Math.random(0, 1)
			}
		}
	}

	crossover(partner) {
		let sibling = new Array(this.genes.length)
		for (let i = 0; i < this.genes.length; i++) {
			let gene = (Math.random(0, 1) > 0.5) ?  this.genes[i] : partner.genes[i]
			sibling[i] = gene
		}
		let newgenes = new DNA(sibling)
		return newgenes
	}

	mutate(m) {
		for (let i = 0; i < this.genes.length; i++) {
			if (Math.random(1) < m) {
				this.genes[i] = Math.random(0, 1)
			}
		}
	}
}

export default DNA
